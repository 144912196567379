@import url('https://fonts.googleapis.com/css2?family=Lato&family=Raleway:wght@700&display=swap" rel="stylesheet');
@import "bourbon";

$maincolor: #000;

.App {
  background: $maincolor;
  color: #ffffff;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

section {
  scroll-snap-align: center;
}

#about-me {
  scroll-snap-align: center;
}

h2,
h1 {
  margin: 0;
}

h1 {
  font-size: 75px;
}

h2 {
  font-size: 50px;
  margin-bottom: 6vh;
}

.header {
  background-image: url(./images/unsplash-KwJ3FEuwRlE-unsplash.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-family: "Raleway", sans-serif;
  color: #ffffff;
  height: 100vh;
  font-size: 60px;
  font-weight: bold;
  
}

.resume-background {
  height: 100vh;
  background-image: url(./images/reza-namdari-ZgZsKFnSbEA-unsplash.jpg);

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .Resume {
    font-family: "Lato", sans-serif;
    display: flex;
    justify-content: center;
    text-align: center;
    b {
      font-weight: 900;
    }
  }

  .my-picture {
    height: 50vh;
  }

  .my-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
  }

  .resume-title {
    color: #ffffff;
    font-size: 50px;
    text-align: center;
    font-family: "Raleway", sans-serif;
  }
  .uofa-div {
    margin-bottom: 45px;
  }
}

.Skills {
  display: flex;
  justify-content: center;
  height: 100vh;

  background-image: url(./images/markus-spiske-hvSr_CVecVI-unsplash.jpg);

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-family: "Lato", sans-serif;

  h1 {
    text-align: center;
    margin: auto;
    font-family: "Raleway", sans-serif;
  }

  .skill-box {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }

  .skill-boxes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5vh;
  }

  .skill-logo-box-individual {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    padding-right: 4px;
    padding-left: 4px;
  }

  .skill-logo {
    height: 80px;
    margin: auto;
  }

 

  ul {
    padding-left: 0px;
  }
}

.projects-background {
  background-image: url(./images/florian-olivo-4hbJ-eymZ1o-unsplash.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: auto;
  height: 100vh;

  .projects {
    display: flex;
    flex-wrap: wrap;
  }
}

// .project-image {
//   height: 30vh;
//   width: 45vh;
// }

.individual-project {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  margin-bottom: 30px;
  margin-top: 30px;
}

.project-headers {
  color: #ffffff;
  font-size: 40px;
  margin-bottom: 10px;
  font-family: "Lato", sans-serif;
}

.project-headers:hover {
  color: #c411e7;
}

.projects-title {
  color: #ffffff;
  font-size: 50px;
  text-align: center;
  font-family: "Raleway", sans-serif;
}

a {
  text-decoration: none;
}

.btn-container {
  text-align: center;
}

.elegant {
  // display: inline-block;
  margin: 4em auto;
  padding: 10px 20px;
  border: 1px solid #cc9900;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  margin: 30px auto;
  color: #333333;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  background: #fafafa;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -18px;
    left: 52%;
    width: 30px;
    height: 10px;
    border-top: 1px solid #cc9900;
    transform: (rotate(-65deg));
    transition: (all 0.7s ease);
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -22px;
    left: 38%;
    width: 30px;
    height: 10px;
    border-top: 1px solid #cc9900;
    transform: (rotate(-65deg));
    transition: (all 0.7s ease);
  }
  &:hover,
  &:focus,
  &:active {
    &:before {
      transform: (rotate(-130deg));
      top: -21px;
      left: 35%;
    }
    &:after {
      transform: (rotate(-130deg));
      left: 60%;
      bottom: -15px;
    }
  }
}

#contact {
  display: flex;
  justify-content: center;
  flex-direction: column;

  h1 {
    text-align: center;
    font-family: "Raleway", sans-serif;
  }
  p {
    text-align: center;
    font-family: "Lato", sans-serif;
  }

  .contact-blurb {
    margin-bottom: 60px;
  }
}

.contact-logo {
  height: 60px;
}

.contact-grid {
  display: flex;
  justify-content: center;
  justify-content: space-around;
  margin-bottom: 60px;
}

.footer-logos {
  display: flex;
  justify-content: center;
}


@media (min-width: 1500px) {
  .project-image {
    height: 35vh;
    width: 59vh;
  }
  .project-headers {
    font-size: 30px;
  }
}



@media (max-width: 1500px) {
  .project-image {
    height: 30vh;
    width: 50vh;
  }
  .project-headers {
    font-size: 30px;
  }
}

@media (max-width: 1237px) {
  .project-image {
    height: 25vh;
    width: 40vh;
  }
  .project-headers {
    font-size: 30px;
  }
}

@media (max-width: 1000px) {
  .project-image {
    height: 22vh;
    width: 33vh;
  }
  .project-headers {
    font-size: 30px;
  }
}



@media (max-width: 850px) {
  .project-image {
    height: 19vh;
    width: 28vh;
  }
  .project-headers {
    font-size: 30px;
  }
}

@media (max-width: 713px) {
  h1 {
    font-size: 50px;
  }
  h2 {
    font-size: 40px;
  }

  .Skills {
    display: flex;
    justify-content: center;
    height: 100vh;
    font-size: 13px;


    background-image: url(./images/markus-spiske-hvSr_CVecVI-unsplash.jpg);

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    font-family: "Lato", sans-serif;

    h1 {
      text-align: center;
      margin: auto;
      font-family: "Raleway", sans-serif;
      margin-bottom: 20px;
    }

    .skill-box {
      display: flex;
      flex-direction: row;
      margin-bottom: 15starpx;
    }

    .skill-logo-box-individual {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: auto;
    }

    .skill-logo {
      height: 70px;
      margin: auto;
    }

   

    ul {
      padding-left: 0px;
    }
  }

  .resume-background {
    height: 100vh;
    background-image: url(./images/reza-namdari-ZgZsKFnSbEA-unsplash.jpg);

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .Resume {
      font-family: "Lato", sans-serif;
      display: flex;
      justify-content: center;
      text-align: center;
      b {
        font-weight: 900;
      }
    }

    .my-picture {
      height: 35vh;
    }

    .my-info {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .resume-title {
      color: #ffffff;
      font-size: 50px;
      text-align: center;
      font-family: "Raleway", sans-serif;
    }
  }

  .project-image {
    height: 16vh;
    width: 28vh;
  }

  .project-headers {
    font-size: 20px;
  }

  .individual-project {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    margin-bottom: 10px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
}
